import React from 'react';

import { Route } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ApiKeys from './ApiKeys';
import ApiLogs from './ApiLogs';
import ApiStats from './ApiStats';
import ApiDocs from './ApiDocs';
import { WithSessionToken } from './modules/token';
import { useUsername } from './modules/token';
import clsx from 'clsx';

import { clearToken } from './modules/token';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  user: {
    marginLeft: 20,
    paddingTop: 15,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

function Dashboard() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleProfileMenuOpen = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = (event) => {
    setAnchorEl(null);
  };

  const menuId = 'navbar-account-menu';
  const handleSignOut = () => {
    clearToken();
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleProfileMenuClose}
    >
      <MenuItem onClick={handleSignOut}>Log Out</MenuItem>
    </Menu>
  );

  return (
    <WithSessionToken>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap style={{ flex: 1 }}>
            PropertyQuants QValue User Dashboard
          </Typography>
          <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={clsx(classes.toolbar, classes.user)} >
          <Typography variant="h6" noWrap>
            {'Hello ' + useUsername()}
          </Typography>
        </div>
        <Divider />
        <List>
          {[['Stats', '/stats'], ['Api Keys', '/keys'], ['Logs', '/logs']].map((pair, index) => (
            <Link href={pair[1]}>
              <ListItem button key={pair[0]}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={pair[0]} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
        <List>
          <Link href={'/docs'}>
            <ListItem button>
              <ListItemIcon> <MailIcon /></ListItemIcon>
              <ListItemText primary={'Api Docs'} />
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <>
          <Route path="/stats" exact component={ApiStats} />
          <Route path="/keys" exact component={ApiKeys} />
          <Route path="/logs" exact component={ApiLogs} />
          <Route path="/docs" exact component={ApiDocs} />
          <Route path="/" exact component={ApiStats} />
        </>
      </main>
    </div>
    </WithSessionToken>
  );
}

export default Dashboard;