import React from 'react';

import SignIn from './SignIn';
import SignUp from './SignUp';
import SignOut from './SignOut';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

import Dashboard from './Dashboard';
import { Route } from "react-router-dom";

import { initSessionToken } from './modules/token';

/*

      <Route path="/sign-up" component={SignUp} />
  */

function App() {
  initSessionToken();

  return (
    <>
      <Route path="/sign-in" exact component={SignIn} />
      <Route path="/-sign-up" exact component={SignUp} />
      <Route path="/sign-out" exact component={SignOut} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/reset-password" exact component={ResetPassword} />
      <Route path="/stats" exact component={Dashboard} />
      <Route path="/keys" exact component={Dashboard} />
      <Route path="/logs" exact component={Dashboard} />
      <Route path="/docs" exact component={Dashboard} />
      <Route path="/" exact component={Dashboard} />
    </>
  );
}


  
export default App;
