import React from 'react'
import { JsonDataLoader } from './modules/json_data_loader'
import { getToken } from './modules/token'
import { Typography } from '@material-ui/core';

import ApiStatsTable from './modules/views/ApiStatsTable';

function ApiStats(props) {
  return (
    <>
      <Typography variant="h4" noWrap>
        Api Stats
      </Typography>
      <hr style={{marginTop: 40, marginBottom:40}}/>
      <JsonDataLoader url={'/api/v1/apistats'} options={{headers:{Authorization: 'Bearer ' + getToken().token}}}>
        {({data, loading, error}) => {
          if (error) {
            return <div> Failed to load data </div>;
          }
          if (loading) {
            return <div> loading </div>;
          }
          return (
            <div>
              <ApiStatsTable data={data}/>
            </div>
          );
        }}
      </JsonDataLoader>
    </>
  );
}

export default ApiStats;