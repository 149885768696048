import React from 'react';

import { Typography } from '@material-ui/core';
//import Markdown from './modules/views/MarkDown';
import ReactMarkdown from 'markdown-to-jsx';
import api from './api.md'

function ApiDocs() {

  const [apiText, setApiText] = React.useState('');

  fetch(api).then((resp)=> resp.text()).then((resp)=>{setApiText(resp); console.log(typeof resp);});

  return (
    <>
      <Typography variant="h4" noWrap>
        API Docs
      </Typography>
      <p>Last Updated: 6 May 2020</p>
      <hr style={{marginTop: 40, marginBottom:40}}/>

      <ReactMarkdown>{apiText}</ReactMarkdown>
    </>  
  );
}

export default ApiDocs;


