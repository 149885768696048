import React from 'react'

import { FORM_ERROR } from 'final-form'

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';

import { email, required } from './modules/validation';
import { setToken } from './modules/token';
import { postObject } from './modules/network'

import ResetPasswordForm from './modules/views/ResetPasswordForm'


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}));

function ForgotPassword(props) {
  const classes = useStyles();
  
  const [sent, setSent] = React.useState(false);

  const validate = (values) => {
    const errors = required(['new_password', 'code'], values);

    return errors;
  };

  const onSubmit = async (values) => {
    const url = '/api/v1/reset-password';

    try {
      const resp = await postObject(url, values);
      
      const results = await resp.json();
      if (results.error_code === 0) {
        setSent(true);
      }
      else {
        console.log("Failed to reset password");
        return {[FORM_ERROR]: results.reason};
      }
      setSent(true);
    } catch(error) {
      console.log("Failed to connected to server");
      return {[FORM_ERROR]: "Failed to connected to server, Please try again later"};
    }
  };

  return (
    <React.Fragment>   
      <Container component="main" style={{maxWidth: 600}}>
        <Paper className={classes.paper}>
          { sent ? (
            <>
              <p><span>You have successfully reset your password. Please <a href="/sign-in">Sign in</a> </span> </p>
            </>) : (
              <ResetPasswordForm validate={validate} onSubmit={onSubmit}/>
            )}
        </Paper>
      </Container>
    </React.Fragment>
    );
  }


export default ForgotPassword;

