import React from 'react'
import Container from '@material-ui/core/Container'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Form, FormSpy } from 'react-final-form';
import { FORM_ERROR } from 'final-form'
import { JsonDataLoader } from './modules/json_data_loader'
import { getToken } from './modules/token'
import ApiKeyTable from './modules/views/ApiKeyTable'


const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  feedback: {
    color: 'red',
  }
}));


function ApiKeys(props) {
  const classes = useStyles();

  const [newKey, setNewKey] = React.useState(null);

  const createNewKey = async () => {
    const resp = await fetch('/api/v1/apikey', {method: 'POST', headers: {Authorization: 'Bearer ' + getToken().token}});

    if (resp.status === 401) {
      return {[FORM_ERROR]: "Failed to authorize"};
    }
    else if (resp.status > 400) {
      return {[FORM_ERROR]: "Failed to connected to server"};
    }
    const results = await resp.json();
    setNewKey(results.api_key);
  }

  return (
    <Container>
      <Form onSubmit={createNewKey} subscription={{ submitting: true }} validate={null}>
        {({ handleSubmit, submitting }) => (
          <React.Fragment>
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Button
                className={classes.submit}
                disabled={submitting}
                size="large"
                color="secondary"
                type="submit" 
                variant="contained"
              >
                {submitting ? 'In progress…' : 'Create a New Secret API Key'}
              </Button>
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <div className={classes.feedback} error>
                      {submitError}
                    </div>
                  ) : null
                }
              </FormSpy>
              {newKey ? <p>{newKey}</p> : <></>}
            </form>
          </React.Fragment>
        )}
      </Form>

      <hr style={{'marginTop': 40, 'marginBottom': 40}}/>

      <div>
        <Typography variant="h4" noWrap>
          Existing Keys
        </Typography>
        <br/>
        <JsonDataLoader url={'/api/v1/apikey'} options={{headers:{'Authorization': 'Bearer ' + getToken().token}}}>
          {({data, loading, error}) => {
            if (loading) {
              return <div> loading existing keys </div>;
            }
            if (error) {
              return <div> Failed to load existing keys </div>
            }
            return <ApiKeyTable api_keys={data.api_keys} />;
          }}
        </JsonDataLoader>
      </div>
    </Container>
  );
}

export default ApiKeys;