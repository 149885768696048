
function postFormData(url, form_data) {
    return fetch(url, {
        method: 'POST',
        body: form_data
    });
  }
  
  
  function postObject(url, obj) {
    const data = Object.keys(obj).reduce(function(output, k) {output.append(k, obj[[k]]); return output;}, new FormData());
    return postFormData(url, data);
  }
  
  
  export {
    postFormData,
    postObject
  };
  