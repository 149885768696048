import React from 'react'
import Container from '@material-ui/core/Container'
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main, //'DarkSlateGray', //theme.palette.common.black,
    color: theme.palette.common.white,
    paddingLeft: 8,
    paddingRight: 8
  },
  body: {
    fontSize: 16,
    paddingLeft: 8,
    paddingRight: 8
  },
}))(TableCell);


function ApiKeyTable(props) {
  return (
    <Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align='left'> Key </StyledTableCell>
              <StyledTableCell align='left'> Created </StyledTableCell>
              <StyledTableCell align='left'> Status </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.api_keys || []).map((api_key, index) => (
              <TableRow key={api_key.prefix + index}>
                <StyledTableCell>{api_key.prefix + '.***'}</StyledTableCell>
                <StyledTableCell>{(new Date(api_key.created_at*1000).toISOString())}</StyledTableCell>
                <StyledTableCell>{api_key.activated? 'Activated' : 'Inactivated'}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {(props.api_keys && props.api_keys.length) ? <></> : <p>You haven't created any api key yet.</p>}
      </TableContainer>
    </Container>
  );
}

export default ApiKeyTable;