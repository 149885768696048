import React from 'react'

import { FORM_ERROR } from 'final-form'

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';

//import AppFooter from './modules/views/AppFooter';
//import AppAppBar from './modules/views/AppAppBar';
import { email, required } from './modules/validation';
import { setToken, initSessionToken } from './modules/token';
import { postObject } from './modules/network'

import SignInForm from './modules/views/SignInForm'


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}));

function SignIn(props) {
  const classes = useStyles();
  
  const src_url = new URLSearchParams(props.location.search).get('from') || '/';

  const [sending, setSending] = React.useState(false);

  const validate = (values) => {
    const errors = required(['email', 'password'], values);

    if (!errors.email) {
      const emailError = email(values.email, values);
      if (emailError) {
        errors.email = email(values.email, values);
      }
    }
    return errors;
  };

  const onSubmit = async (values) => {
    setSending(true);

    const url = '/api/v1/sign-in';

    try {
      const resp = await postObject(url, values);
      const resp_data = await resp.json();

      setSending(false);
      
      if (resp_data['error_code'] === 3) {
        return {'email': resp_data['reason']};
      }
      else if (resp_data['error_code'] > 0) {
        return {[FORM_ERROR]: resp_data['reason']};
      }
      console.log(resp_data, resp_data.token);
      setToken(resp_data.token);
      initSessionToken();
      document.location.href = src_url;
    } catch(error) {
      setSending(false);
      console.log("Failed to connected to server");
      return {[FORM_ERROR]: "Failed to connected to server"};

    }
  };

  return (
    <React.Fragment>   
      <Container component="main" style={{maxWidth: 600}}>
        <Paper className={classes.paper}>
          <SignInForm validate={validate} onSubmit={onSubmit} sending={sending}/>
        </Paper>
      </Container>
    </React.Fragment>
    );
  }


export default SignIn;

