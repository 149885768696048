import React from 'react'

import { FORM_ERROR } from 'final-form'

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';

//import AppFooter from './modules/views/AppFooter';
//import AppAppBar from './modules/views/AppAppBar';
import { email, required } from './modules/validation';
import { setToken } from './modules/token';
import { postObject } from './modules/network'

import ForgotPasswordForm from './modules/views/ForgotPasswordForm'


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}));

function ForgotPassword(props) {
  const classes = useStyles();
  
  const [sent, setSent] = React.useState(false);

  const validate = (values) => {
    const errors = required(['email'], values);

    if (!errors.email) {
      const emailError = email(values.email, values);
      if (emailError) {
        errors.email = email(values.email, values);
      }
    }
    return errors;
  };

  const onSubmit = async (values) => {
    const url = '/api/v1/forgot-password';

    try {
      const resp = await postObject(url, values);
      const resp_data = await resp.json();
      
      setSent(true);
    } catch(error) {
      console.log("Failed to connected to server");
      return {[FORM_ERROR]: "Failed to connected to server, Please try again later"};
    }
  };

  return (
    <React.Fragment>   
      <Container component="main" style={{maxWidth: 600}}>
        <Paper className={classes.paper}>
          { sent ? (
            <>
              <p>You will receive a password-resetting email if the email address is registered with us. </p>
              <p>Please check your mailbox (spam if necessary) and follow the instructions to reset your password.</p>
            </>) : (
              <ForgotPasswordForm validate={validate} onSubmit={onSubmit}/>
            )}
        </Paper>
      </Container>
    </React.Fragment>
    );
  }


export default ForgotPassword;

