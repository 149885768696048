import React from 'react'
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom'
import { JsonDataLoader } from './json_data_loader'
import { useSessionStorage } from './useStorage'

const USER_INFO_EVENT = 'user_info_event'


function setToken(token) {
  console.log('settting', token);
  window.localStorage.setItem('session-token', JSON.stringify(token));
}

function clearToken() {
  window.localStorage.removeItem("session-token");
  window.sessionStorage.removeItem('username');
  window.sessionStorage.removeItem('email');
  window.dispatchEvent(new Event(USER_INFO_EVENT));
}

function getToken() {
  let token = window.localStorage.getItem("session-token");
  //console.log('get', token);
  if (token) {
    token = JSON.parse(token);
    //console.log('get2', token);
    if (token.expiry  < (new Date()).getTime() / 1000) {
      token = null;
    }
  }
  return token;
}

function _initSessionToken() {
  const session_token = getToken();
  if (session_token) { 
    if (!getEmail()) {
      fetch('/api/v1/user-info', {headers: {'Authorization': 'Bearer ' + session_token.token}})
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp['error_code'] === 0) {
          window.sessionStorage.setItem('username', resp.data['username']);
          window.sessionStorage.setItem('email', resp.data['email']);
          window.dispatchEvent(new Event(USER_INFO_EVENT));
        }
        else {
          clearToken();
        }
      });
    }
    else {
      window.dispatchEvent(new Event(USER_INFO_EVENT));
    }
  }
}

function initSessionToken() {
  setTimeout(_initSessionToken, 100);
}

function getEmail() {
  return window.sessionStorage.getItem("email");
}

function useEmail() {
  return useSessionStorage(USER_INFO_EVENT, 'email');
}

function getUsername() {
  return window.sessionStorage.getItem("username");
}

function useUsername() {
  return useSessionStorage(USER_INFO_EVENT, 'username');
}

function WithSessionToken(props) {
  if (getToken()) {
    return (
      <>
        { props.children }
      </>
    );
  }
  return <Redirect to={'/sign-in?from=' + window.location.pathname} />;
}

WithSessionToken.propTypes = {
  children: PropTypes.object.isRequired,
};

export {
  initSessionToken,
  setToken,
  clearToken,
  WithSessionToken,
  useEmail,
  getEmail,
  useUsername,
  getUsername,
  getToken,
};
