import React from 'react'

import { FORM_ERROR } from 'final-form'

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';

//import AppFooter from './modules/views/AppFooter';
//import AppAppBar from './modules/views/AppAppBar';
import { email, required } from './modules/validation';
import { postObject } from './modules/network'

import SignUpForm from './modules/views/SignUpForm'


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}));

function SignUp(props) {
  const classes = useStyles();
  
  const [sent, setSent] = React.useState(false);

  const validate = (values) => {
    const errors = required(['email'], values);

    if (!errors.email) {
      const emailError = email(values.email, values);
      if (emailError) {
        errors.email = email(values.email, values);
      }
    }
    return errors;
  };

  const onSubmit = async (values) => {
    const url = '/api/v1/-sign-up';

    try {
      const resp = await postObject(url, values);
      const resp_data = await resp.json();
      
      if (resp_data['error_code'] === 3) {
        return {'email': resp_data['reason']};
      }
      else if (resp_data['error_code'] > 0) {
        return {[FORM_ERROR]: resp_data['reason']};
      }

      setSent(true);
    } catch(error) {
      console.log("Failed to connected to server");
      return {[FORM_ERROR]: "Failed to connected to server, Please try again later"};
    }
  };

  return (
    <React.Fragment>   
      <Container component="main" style={{maxWidth: 600}}>
        <Paper className={classes.paper}>
          { sent ? (
            <>
              <p>Thank you for signing up!</p>
              <p>To serve you better, we will need to confirm your email address. </p>
              <p>Please check your mailbox (in spam if necessary) and follow the instructions.</p>
            </>) : (
              <SignUpForm validate={validate} onSubmit={onSubmit}/>
            )}
        </Paper>
      </Container>
    </React.Fragment>
    );
  }


export default SignUp;

