
import React from 'react'

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { Field, Form, FormSpy } from 'react-final-form';
import { TextField } from 'final-form-material-ui'

const useStyles = makeStyles((theme) => ({
  paper: {
    //marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  feedback: {
    color: 'red',
  }
}));


function SignUpForm(props) {
  const classes = useStyles();
  const {onSubmit, validate, sending} = props;

  return (  
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Form onSubmit={onSubmit} subscription={{ submitting: true }} validate={validate}>
          {({ handleSubmit, submitting }) => (
            <React.Fragment>
              <form onSubmit={handleSubmit} className={classes.form} noValidate>
                <Field
                  autoFocus
                  component={TextField}
                  disabled={submitting || sending}
                  fullWidth
                  label="Username"
                  margin="normal"
                  name="username"
                  required
                  size="large"
                  variant="outlined"
                />
                <Field
                  autoComplete="email"
                  component={TextField}
                  disabled={submitting || sending}
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  required
                  size="large"
                  variant="outlined"
                />
                <Field
                  fullWidth
                  size="large"
                  component={TextField}
                  disabled={submitting || sending}
                  required
                  name="password"
                  autoComplete="current-password"
                  label="Password"
                  type="password"
                  margin="normal"
                  variant="outlined"
                />
                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <div className={classes.feedback} error>
                        {submitError}
                      </div>
                    ) : null
                  }
                </FormSpy>
                <Button
                  className={classes.submit}
                  disabled={submitting || sending}
                  size="large"
                  color="primary"
                  fullWidth
                  type="submit" 
                  variant="contained"
                >
                  {submitting || props.sending ? 'In progress…' : 'Sign Up'}
                </Button>
              </form>
            </React.Fragment>
          )}
        </Form>
      </div>
    </Container>
  );
}


export default SignUpForm;

