import React from 'react'
import Container from '@material-ui/core/Container'
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main, //'DarkSlateGray', //theme.palette.common.black,
    color: theme.palette.common.white,
    paddingLeft: 8,
    paddingRight: 8
  },
  body: {
    fontSize: 16,
    paddingLeft: 8,
    paddingRight: 8
  },
}))(TableCell);


function ApiStatsTable(props) {
  const {stats, resources} = props.data;

  return (
    <Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align='left'> Date </StyledTableCell>
              <StyledTableCell align='left'> Resource </StyledTableCell>
              <StyledTableCell align='left'> Count </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(stats || []).map((record, index) => (
              <TableRow key={record.date + '/' + record.resource}>
                <StyledTableCell>{record.date}</StyledTableCell>
                <StyledTableCell>{resources[record.resource]}</StyledTableCell>
                <StyledTableCell>{record.count}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default ApiStatsTable;