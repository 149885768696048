import React from 'react'

function useSessionStorage(event_name, key) {
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    function handleStorage() {
      setValue(window.sessionStorage.getItem(key));
    }

    window.addEventListener(event_name, handleStorage);
    
    return () => window.removeEventListener(event_name, handleStorage);
  }, [event_name, key]);

  return value;
}


function useLocalStorage(event_name, key) {
  const [value, setValue] = React.useState(window.localStorage.getItem(key));

  React.useEffect(() => {
    function handleStorage() {
      setValue(window.localStorage.getItem(key));
    }

    window.addEventListener(event_name, handleStorage);
    
    return () => window.removeEventListener(event_name, handleStorage);
  }, [event_name, key]);

  return value;
}


export {
  useSessionStorage,
  useLocalStorage,
}