import React from 'react'
import  { Redirect } from 'react-router-dom'
import { clearToken } from './modules/token'

function SignOut(props) {
  // const token = window.sessionStorage.getItem('session-token');
  clearToken();
  return <Redirect to={'/'} />;
}

export default SignOut;
