import React from 'react'
import { JsonDataLoader } from './modules/json_data_loader'
import { getToken } from './modules/token'
import ApiLogsTable from './modules/views/ApiLogsTable';
import { Typography } from '@material-ui/core';

function ApiLogs(props) {
  return (
    <>
      <Typography variant="h4" noWrap>
        Api Logs
      </Typography>
      <hr style={{marginTop: 40, marginBottom:40}}/>
      <JsonDataLoader url={'/api/v1/apilogs'} options={{headers:{Authorization: 'Bearer ' + getToken().token}}}>
        {({data, loading, error}) => {
          if (error) {
            return <div> Failed to load data </div>;
          }
          if (loading) {
            return <div> loading </div>;
          }
          return (
            <div>
              <ApiLogsTable data={data}/>
            </div>
          );
        }}
      </JsonDataLoader>
    </>
  );
}

export default ApiLogs;